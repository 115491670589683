import React, { Suspense, useEffect, useState } from "react";
import { Route, Routes, Navigate, useParams, useNavigate } from "react-router-dom";
import withRouter from "../hooks/withRouter"
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { Home, About, Blog, Blogs, ContactUs, Portfolio, NotFound, Socialicons } from "./lazyLoad";
import { SpinnerComponent } from "../components/loader"



const AnimatedRoutes = withRouter(({ location }) => {
  const locations = ["Faridabad", "Greater-Faridabad", "Ghaziabad", "Noida", "Greater-Noida", "Delhi", "Gurgaon", "Gurugram", "SurajKund-Faridabad"];
  const occasions = ["Professional", "Reception", "Farewell", "Bridal HD", "Bridal", "Engagement", "Party", "Occasional", "Wedding"];
  return (
    <TransitionGroup>
      <CSSTransition
        key={location.key}
        timeout={{
          enter: 400,
          exit: 400,
        }}
        classNames="page"
        unmountOnExit
      >
        <Suspense fallback={<SpinnerComponent />}>
          <Routes location={location}>
            <Route exact path="/" element={<Home placeName={"Delhi NCR"} urlroute={"/"} occasion={"Professional"} />} />
            <Route path="/about" element={<About />} />
            <Route path="/portfolio" element={<Portfolio occasion={"Professional"} urlseo={"/portfolio"} location={"Delhi NCR"} />} />
            <Route path="/contact" element={<ContactUs occasion={"Professional"} urlseo={"/contact"} location={"Delhi NCR"} />} />
            <Route path="*" element={<Navigate to='/404' />} />
            <Route path="/404" element={<NotFound />} />

            {/* Location and Occasion Based Links */}
            <Route path={`/book-best-makeup-artist-delhi-ncr`} element={<ContactUs occasion={"Professional"} urlseo={"/book-best-makeup-artist-delhi-ncr"} location={"Delhi NCR"} />} />
            <Route path={`/book-professional-makeup-artist-delhi-ncr`} element={<ContactUs occasion={"Professional"} urlseo={"/book-professional-makeup-artist-delhi-ncr"} location={"Delhi NCR"} />} />
            <Route path={`/book-best-bridal-makeup-artist-meerut`} element={<ContactUs occasion={"Bridal"} urlseo={"/book-best-bridal-makeup-artist-meerut"} location={"Meerut"} />} />
            <Route path={`/book-best-bridal-makeup-artist-jaipur`} element={<ContactUs occasion={"Bridal"} urlseo={"/book-best-bridal-makeup-artist-jaipur"} location={"Jaipur"} />} />

            <>
              {locations.map(location => {
                return occasions.map(occasion => {
                  const loc = location.toLowerCase().replace(" ", "-");
                  const homeurlroute = `/${occasion.toLowerCase().replace(" ", "-")}-makeup-artist-${loc}`;
                  const portfoliourlseo = `/${occasion.toLowerCase().replace(" ", "-")}-makeup-artist-portfolio-${loc}`;
                  const contactUrl = `/book-${occasion.toLowerCase().replace(" ", "-")}-makeup-artist-${loc}`;

                  return (
                    <>
                      <Route exact path={homeurlroute} element={<Home placeName={location} urlroute={homeurlroute} occasion={occasion} />} />
                      <Route path={portfoliourlseo} element={<Portfolio occasion={occasion} urlseo={portfoliourlseo} location={location} />} />
                      <Route path={contactUrl} element={<ContactUs occasion={occasion} urlseo={contactUrl} location={location} />} />
                    </>
                  );
                });
              })}

            </>

            <Route path="/blogs" element={<Blogs />} />
            <Route path="/blog/:content" element={<Blog />} />
            {/* <Route path="/blogs/all" element={<BlogsAll />} /> */}

            {/* <Route path="/contact/:anything" element={<ContactRedirect />} /> */}
          </Routes>
        </Suspense>

      </CSSTransition>
    </TransitionGroup>
  );
});

function AppRoutes() {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    // Set a timer to load the content after the page has loaded
    const timer = setTimeout(() => {
      setIsLoaded(true);
    }, 5000); // Adjust the delay as needed

    // Cleanup the timer on component unmount
    return () => clearTimeout(timer);
  }, []);
  
  return (
    <div className="s_c">
      <AnimatedRoutes />

      {isLoaded && (
        <Suspense>
          <Socialicons />
        </Suspense>)}

    </div>
  );
}
//Will be removed once all the contact ranking superseeded by book- ranking.
// const ContactRedirect = () => { const { anything } = useParams(); const navigate = useNavigate(); useEffect(() => { navigate(`/book-${anything}`, { replace: true }); }, [anything, navigate]); return null; };

export default AppRoutes;
